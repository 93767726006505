import React from "react";
import { graphql } from "gatsby";
// import tw from "twin.macro";
// import styled from "@emotion/styled";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GraphQLErrorList from "../components/Blog/graphql-error-list";

import CallToAction from "../components/Repeating/CTA";
import ServiceSection1 from "../components/Service/ServiceSection1";
import ServiceSection2 from "../components/Service/ServiceSection2";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
import RecentPosts from "../components/Repeating/RecentPosts";
// const StyledContent = styled.div`
//   ul {
//     ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
//   }
//   ol {
//     ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
//   }  
//   p {
//     ${tw`text-[16px] font-normal`}
//   }
// `;
const HospicePage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="San Diego Hospice Care | HarborView Senior Assisted Living"
        description="At HarborView Senior Assisted Living in San Diego, we provide warm, comforting surroundings for those that require hospice care. Contact us to learn more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <ServiceSection1
        sectionTitle={data.sanityHospicecare.title}
        sectionDesc={data.sanityHospicecare.description}
        contents={data.sanityHospicecare}
      />
      <ServiceSection2
        title1={data.sanityHospicecare.subtitle1}
        description1={data.sanityHospicecare._rawSubdescription1}
        title2={data.sanityHospicecare.title2}
        description2={data.sanityHospicecare._rawSubdescription2}
        title3={data.sanityHospicecare.subtitle3}
        description3={data.sanityHospicecare._rawSubdescription3}
        sectionNumber={3}
      />

      <HarborViewLifestyle
        className="mb-14  bg-secondary-400 p-4 lg:p-24 md:mb-24 "
        hide={4}
      />
      <WhyUs />
      <Testimonials />
      <RecentPosts />
      <CallToAction
        phonenumber={data.contactInfo.phonenumber}
        description={data.sanityHospicecare.ctacontent}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }

    sanityHospicecare {
      title
      description
      bgimage {
        asset {
          gatsbyImageData
        }
      }
      mobilebgimage {
        asset {
          gatsbyImageData
        }
      }
      subtitle1
      _rawSubdescription1
      title2
      _rawSubdescription2
      subtitle3
      _rawSubdescription3
      subctacontent
    }
  }
`;

export default HospicePage;
